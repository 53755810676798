






























































import {
  defineComponent,
  computed,
  useRouter,
  useContext
} from '@nuxtjs/composition-api'
import { bannerProps, useCmsBanner } from '@wemade-vsf/composables';
import { useImage } from '@wemade-vsf/composables'
import ThemeBanner from 'components/theme/Content/Banner.vue';
import { SfButton, SfHeading } from '@storefront-ui/vue'

export default defineComponent({
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    video: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'hero-banner'
    },
    heightmobile: {
      type: Number,
      default: 0
    },
    heightdesktop: {
      type: Number,
      default: 0
    },
    ...bannerProps
  },
  components: {
    ThemeBanner,
    SfButton,
    SfHeading
  },
  setup (props) {
    const { localePath } = useContext()
    const router = useRouter()
    const { magentoImage, getComponentClasses, isExternal, isNewTab } = useCmsBanner(props)
    const { getMagentoImage } = useImage()

    const dynamicClasses = computed(() => getComponentClasses())
    const bannerStyles = computed(() => {
      let styles = {}
      if (props.heightmobile) {
        styles['--_bannerHeightMobile'] = props.heightmobile + 'px'
      }
      if (props.heightdesktop) {
        styles['--_bannerHeightDesktop'] = props.heightdesktop + 'px'
      }
      return styles
    })

    const onCtaClick = () => {
      if (props.link === '#' || process.server) return
      if (isExternal) {
        isNewTab
          ? window.open(props.link, '_blank').focus()
          : window.location.href = props.link
      } else {
        router.push(localePath(props.link))
      }
    }


    return {
      dynamicClasses,
      getMagentoImage,
      magentoImage,
      bannerStyles,
      onCtaClick
    }
  }
})
