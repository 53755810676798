



































import {
  defineComponent,
  computed,
  useContext,
  onMounted,
  ref,
  PropType
} from '@nuxtjs/composition-api'
import { SfLink, SfButton } from '@storefront-ui/vue'
import { useImage } from '@wemade-vsf/composables'

export default defineComponent({
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: "",
    },
    image: {
      type: [String, Object],
      default: "",
    },
    imageTag: {
      type: String,
      default: null,
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({
        format: "webp"
      }),
    },
    video: {
      type: String as PropType<string>,
      default: ''
    },
    gradient: {
      type: String,
      default: ''
    }
  },
  components: {
    SfLink,
    SfButton
  },
  setup (props) {
    const { app } = useContext()
    const { getMagentoImage } = useImage()
    const videoRef = ref(null)
    const { $img } = app
    
    const hasButtonText = computed(() => props.buttonText.trim().length > 0)
    const posterImage = computed(() => {
      if (!props.video) {
        return ''
      }
      return props.image['desktop'] ? props.image['desktop'] : props.image
    })
    const style = computed(() => {
      const image = props.image
      const background = props.background
      const nuxtImgConvert = (imgUrl) => {
        return `url(${$img(imgUrl, props.nuxtImgConfig)})`
      }

      if (props.imageTag === "nuxt-img" || props.imageTag === "nuxt-picture") {
        return {
          "--_banner-background-image": image['mobile']
            ? nuxtImgConvert(getMagentoImage(image['mobile']))
            : nuxtImgConvert(getMagentoImage(image)),
          "--_banner-background-desktop-image":
            image['desktop'] && nuxtImgConvert(getMagentoImage(image['desktop'])),
          "--_banner-background-color": background,
        }
      } else {
        return {
          "--_banner-background-image": image['mobile']
          ? `url(${getMagentoImage(image['mobile'])})`
          : `url(${getMagentoImage(image)})`,
          "--_banner-background-desktop-image":
            image['desktop'] && `url(${getMagentoImage(image['desktop'])})`,
          "--_banner-background-color": background,
        }
      }
    })
    const isMobile = computed(() => app.$device.isMobile )
    const wrapper = computed(() => {
      if (!isMobile.value) {
        return hasButtonText ? 'div' : 'SfLink'
      }
      return props.link ? 'SfLink' : 'SfButton'
    })
    
    onMounted(() => {
      if (props.video && videoRef.value) {
        if (!videoRef.value.playing) {
          videoRef.value.play()
        }
      }
    })

    return {
      isMobile,
      posterImage,
      hasButtonText,
      style,
      wrapper,
      videoRef
    }
  }
})
